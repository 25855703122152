import React, { FC } from 'react';
import css from './style.module.scss';
import Car, { CarProps, Theme } from '../Car';

export type CarContainerProps = {
  cars: CarProps[];
  theme?: Theme;
  height?: number;
  padding?: string;
  isHorizontal?: boolean;
};

const CarContainer: FC<CarContainerProps> = ({ cars, theme, height, padding, isHorizontal }) => {
  const carContainerClass = isHorizontal ? css['cars-container-stacked'] : css['cars-container'];
  return (
    <div style={{ height, padding }} className={carContainerClass}>
      {cars.map((element) => (
        <Car key={element.url} theme={theme} {...element} horizontal={isHorizontal} />
      ))}
    </div>
  );
};

export default CarContainer;
