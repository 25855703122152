import React, { FC } from 'react';
import css from './style.module.scss';
import { ReactComponent as Arrow } from './assets/chevron_right.svg';

export type SimilarButtonProps = {
  variant?: 'orange' | 'transparent' | 'transparent-light';
  link: string;
  width?: number;
  height?: number;
  padding?: string;
  margin?: string;
  fontSize?: number;
};

const SimilarButton: FC<SimilarButtonProps> = ({
  variant = 'orange',
  link,
  width,
  height,
  padding,
  margin,
  fontSize,
}) => {
  let buttonStyle = css['similar-button'];
  switch (variant) {
    case 'transparent':
      buttonStyle = `${buttonStyle} ${css['similar-button__transparent']}`;
      break;
    case 'transparent-light':
      buttonStyle = `${buttonStyle} ${css['similar-button__transparent-light']}`;
      break;
    default:
      buttonStyle = `${buttonStyle} ${css['similar-button__orange']}`;
      break;
  }
  return (
    <a href={link} target="_blank" rel="noreferrer">
      <div className={buttonStyle} style={{ width, height, padding, margin, fontSize }}>
        <div className={css['content-container']}>
          <span className={css.text}>Hasonló járművek</span>
          <Arrow className={css.arrow} />
        </div>
      </div>
    </a>
  );
};

export default SimilarButton;
