import React, { FC } from 'react';
import css from './style.module.scss';
import { Theme } from '../../index';
import Tooltip from './components/Tooltip';
import { ReactComponent as InfoIcon } from './assets/img/info_outline.svg';

type Types = 'primary' | 'primary_highlighted' | 'inactive';
export type Price = {
  value: string;
  type: Types;
  info?: string;
};
export type PriceDisplayProps = {
  prices: Price[];
  theme?: Theme;
  small?: boolean;
};

const PriceDisplay: FC<PriceDisplayProps> = ({ prices, theme = 'light', small = false }) => {
  const position =
    theme === 'dark'
      ? {
          right: '5px',
          bottom: '120px',
        }
      : {
          right: '5px',
          bottom: '50%',
        };
  const getClassNames = (type: Types): string[] => [
    css['price-display__price'],
    css[`price-display__price--${type}`],
    ...(small ? [css['price-display__price--small']] : []),
  ];
  return (
    <div className={`${css[`price-display--${theme}`]}`}>
      {prices.map(({ value, type, info }) => (
        <div className={getClassNames(type).join(' ')} key={type}>
          {value}
          {info && (
            <Tooltip position={position} title={info}>
              <InfoIcon data-testid="info-icon" className={css['price-display__info']} />
            </Tooltip>
          )}
        </div>
      ))}
    </div>
  );
};
export default PriceDisplay;
