import React, { FC } from 'react';
import css from './style.module.scss';
import Header from '../Header';
import CarContainer from '../CarContainer';
import SimilarButton, { SimilarButtonProps } from '../SimilarButton';
import useContentProvider from '../../services/contentProvider/useContentProvider';
import MetricImage from '../MetricImage';
import { BoxProps } from '../../types/boxProps';

const TripleCarBox: FC<BoxProps> = ({ contentProvider, boxStyle = 'normal' }) => {
  const content = useContentProvider(contentProvider);
  let tripleBox = css['triple-box'];

  if (content === undefined) {
    return <div>Loading...</div>;
  }
  const { ads, searchUrl } = content;
  if (boxStyle === 'narrow') {
    tripleBox = `${tripleBox} ${css['triple-box--alt']}`;
  }
  const transparentButton: SimilarButtonProps = {
    variant: 'transparent',
    link: searchUrl,
    margin: '0 8px 0 0',
  };
  return (
    <div className={tripleBox}>
      <Header
        similarButton={<SimilarButton {...transparentButton} />}
        trackerUrl="https://ad.adverticum.net/C/7180512/7185682/718568700?ct1="
      />
      <CarContainer cars={ads} height={boxStyle === 'narrow' ? 305 : 328} padding="8px 16px 16px 16px" />
      <MetricImage url="https://ad.adverticum.net/t/?z=7180512&g=7180383&b=718055900&h=[LOCATION]&p=2" />
    </div>
  );
};

export default TripleCarBox;
