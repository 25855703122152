import React, { FC } from 'react';
import css from './style.module.scss';
import Header from '../Header';
import CarContainer from '../CarContainer';
import SimilarButton, { SimilarButtonProps } from '../SimilarButton';
import useContentProvider from '../../services/contentProvider/useContentProvider';
import MetricImage from '../MetricImage';
import { BoxProps } from '../../types/boxProps';
import useViewport from './useViewport';

const DualCarBox: FC<BoxProps> = ({ contentProvider, theme }) => {
  const content = useContentProvider(contentProvider);
  const { width } = useViewport();

  if (content === undefined) {
    return <div>Loading...</div>;
  }
  const { ads, searchUrl } = content;
  const similarButton: SimilarButtonProps = {
    variant: 'orange',
    link: searchUrl,
    width: 155,
    height: 30,
    margin: '0 16px 0 0',
    fontSize: 14,
  };
  const adsToRender = width > 575 ? [...ads] : [ads[0]];
  return (
    <div className={css['dual-box']}>
      <CarContainer cars={adsToRender} theme={theme} height={483.5} padding="16px 16px 8px 16px" />
      <Header
        similarButton={<SimilarButton {...similarButton} />}
        trackerUrl="https://ad.adverticum.net/C/7180512/7185683/718568400?ct1="
      />
      <MetricImage url="https://ad.adverticum.net/t/?z=7180512&g=7180395&b=718056700&h=[LOCATION]&p=2" />
    </div>
  );
};

export default DualCarBox;
