import React, { FC } from 'react';
import css from './style.module.scss';
import Header from '../Header';
import SimilarButton, { SimilarButtonProps } from '../SimilarButton';
import CarContainer from '../CarContainer';
import useContentProvider from '../../services/contentProvider/useContentProvider';
import MetricImage from '../MetricImage';
import { BoxProps } from '../../types/boxProps';

const QuadCarBox: FC<BoxProps> = ({ contentProvider }) => {
  const content = useContentProvider(contentProvider);

  if (content === undefined) {
    return <div>Loading...</div>;
  }
  const { ads, searchUrl } = content;
  const similarButton: SimilarButtonProps = {
    variant: 'orange',
    link: searchUrl,
    width: 210,
    height: 42,
    margin: '0 16px 0 0',
  };
  return (
    <div className={css['quad-box']}>
      <Header trackerUrl="https://ad.adverticum.net/C/7180512/7185680/718568500?ct1=" />
      <CarContainer cars={ads} height={355} />
      <div className={css['button-container']}>
        <SimilarButton {...similarButton} />
      </div>
      <MetricImage url="https://ad.adverticum.net/t/?z=7180512&g=7180394&b=718056600&h=[LOCATION]&p=2" />
    </div>
  );
};

export default QuadCarBox;
