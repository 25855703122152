import React, { FC } from 'react';
import css from './style.module.scss';
import { Theme } from '../../index';

export type Parameter = {
  name: string;
  value: string;
};
type ParametersProps = {
  parameters: Parameter[];
  theme?: Theme;
};

const Parameters: FC<ParametersProps> = ({ parameters, theme = 'light' }) => {
  return (
    <ul className={`${css.parameters} ${css[`parameters--${theme}`]}`}>
      {parameters.map(({ name, value }) => (
        <li className={css.parameters__item} key={name}>
          <div className={css.parameters__label}>{name}:</div>
          <div className={css.parameters__value}>{value}</div>
        </li>
      ))}
    </ul>
  );
};
export default Parameters;
