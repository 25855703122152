export type UtmParameters = {
  utm_campaign: string;
  utm_source: string;
  utm_medium: string;
};

const fixedUtmParameters = {
  utm_source: 'totalcar-referral',
  utm_medium: 'banner',
};

export type UtmParameterProvider = () => UtmParameters;

export default ((): UtmParameterProvider => {
  const searchParams = new URLSearchParams(document.location.search);
  return (): UtmParameters => ({
    ...fixedUtmParameters,
    utm_campaign: searchParams.get('utm_campaign') || '',
  });
})();
