import React, { FC, ReactNode, useState } from 'react';
import css from './style.module.scss';

type TooltipProps = {
  title: string;
  children: ReactNode;
  position?: {
    top?: string;
    left?: string;
    right?: string;
    bottom?: string;
  };
};

const Tooltip: FC<TooltipProps> = ({ title, position, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  return (
    // eslint-disable-next-line
    <div className={css.tooltip} onClick={handleClick}>
      {isOpen && (
        <div style={{ ...position }} className={css['tooltip__bubble']}>
          {title}
        </div>
      )}
      {children}
    </div>
  );
};

export default Tooltip;
