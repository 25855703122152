import React, { FC } from 'react';

type MetricImageProps = {
  url: string;
};

const MetricImage: FC<MetricImageProps> = ({ url }) => {
  return <img hidden width={1} height={1} src={url} alt="tracking" />;
};

export default MetricImage;
