import React from 'react';
import './App.css';
import '@fontsource/roboto';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { Route, Routes, useSearchParams } from 'react-router-dom';
import SingleCarBox from './components/SingleCarBox';
import createContentProvider, { Limit, SearchParams } from './services/contentProvider';
import DualCarBox from './components/DualCarBox';
import TripleCarBox from './components/TripleCarBox';
import QuadCarBox from './components/QuadCarBox';
import StackedCardBox from './components/StackedCarBox';

function App() {
  const [searchParams] = useSearchParams();
  const getSearchParams = (limit: Limit): SearchParams => {
    const vintage = searchParams.get('vintage');
    const yearTo = searchParams.get('year_to');
    const priceFrom = searchParams.get('price_from');
    const priceTo = searchParams.get('price_to');

    return {
      brand: searchParams.get('brand') ?? undefined,
      model: searchParams.get('model') ?? undefined,
      fuel_type: searchParams.get('fuel_type') ?? undefined,
      vintage: vintage !== null ? parseInt(vintage, 10) : undefined,
      limit,
      year_to: yearTo !== null ? parseInt(yearTo, 10) : undefined,
      price_from: priceFrom !== null ? parseInt(priceFrom, 10) : undefined,
      price_to: priceTo !== null ? parseInt(priceTo, 10) : undefined,
    };
  };

  return (
    <div className="car-suggester">
      <Routes>
        <Route
          path="single"
          element={
            <SingleCarBox
              boxStyle={searchParams.get('style') ?? undefined}
              contentProvider={createContentProvider(getSearchParams, 1, 'total-car')}
            />
          }
        />
        <Route
          path="dual"
          element={<DualCarBox theme="dark" contentProvider={createContentProvider(getSearchParams, 2, 'total-car')} />}
        />
        <Route
          path="triple"
          element={
            <TripleCarBox
              boxStyle={searchParams.get('style') ?? undefined}
              contentProvider={createContentProvider(getSearchParams, 3, 'total-car')}
            />
          }
        />
        <Route
          path="quad"
          element={<QuadCarBox contentProvider={createContentProvider(getSearchParams, 4, 'total-car')} />}
        />
        <Route
          path="stacked"
          element={<StackedCardBox contentProvider={createContentProvider(getSearchParams, 2, 'index')} />}
        />
      </Routes>
    </div>
  );
}

export default App;
