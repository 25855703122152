import React, { FC } from 'react';
import css from './style.module.scss';
import Header from '../Header';
import SimilarButton, { SimilarButtonProps } from '../SimilarButton';
import CarContainer from '../CarContainer';
import useContentProvider from '../../services/contentProvider/useContentProvider';
import { BoxProps } from '../../types/boxProps';
import MetricImage from '../MetricImage';

const transparentButton: SimilarButtonProps = {
  variant: 'transparent-light',
  link: 'https://www.hasznaltauto.hu',
  fontSize: 12,
  margin: '4px 0',
};

const StackedCardBox: FC<BoxProps> = ({ contentProvider }) => {
  const content = useContentProvider(contentProvider);

  if (content === undefined) {
    return <div>Loading...</div>;
  }
  const { ads } = content;
  return (
    <div className={css['stacked-box']}>
      <Header
        trackerUrl="https://ad.adverticum.net/C/7180512/7478669/747866800?"
        similarButton={<SimilarButton {...transparentButton} />}
        isStacked
      />
      <CarContainer cars={ads} isHorizontal />
      <MetricImage url="https://ad.adverticum.net/t/?z=7180512&g=7478669&b=747866800&h=[LOCATION]&p=2" />
    </div>
  );
};

export default StackedCardBox;
