import React, { FC } from 'react';
import css from './style.module.scss';
import { ReactComponent as HZALogo } from './assets/hasznaltauto_light.svg';
import { addUtmParameters, adverticumUrlWrapper } from '../../services/metricsHandler';

export type HeaderProps = {
  similarButton?: React.ReactNode;
  trackerUrl: string;
  isStacked?: boolean;
};
const targetUrl = 'https://www.hasznaltauto.hu';
const Header: FC<HeaderProps> = ({ similarButton, trackerUrl, isStacked }) => {
  const targetUrlWithUtm = addUtmParameters(targetUrl);
  const logoUrl = adverticumUrlWrapper(targetUrlWithUtm, trackerUrl);
  const logoClass = isStacked ? css['logo-small'] : css.logo;
  return (
    <div data-testid="logo" className={css.header}>
      <a href={logoUrl} target="_blank" rel="noreferrer" className={logoClass} aria-label="hzalogo">
        <HZALogo />
      </a>
      {similarButton}
    </div>
  );
};

export default Header;
