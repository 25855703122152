import React, { FC } from 'react';
import css from './style.module.scss';
import Gallery from './components/Gallery';
import Parameters, { Parameter } from './components/Parameters';
import PriceDisplay, { Price } from './components/PriceDisplay';

export type Theme = 'light' | 'dark';
export type CarProps = {
  name: string;
  prices: Price[];
  photoUrl: string;
  photoCount: number;
  parameters: Parameter[];
  theme?: Theme;
  url: string;
  height?: number;
  horizontal?: boolean;
};

const Car: FC<CarProps> = ({ name, prices, photoUrl, photoCount, parameters, url, theme, height, horizontal }) => {
  const themeClass = theme ? css[`car--${theme}`] : '';
  const horizontalClass = horizontal ? css['car--horizontal'] : '';

  return (
    <a
      style={{ height }}
      href={url}
      target="_blank"
      rel="noreferrer"
      className={`${css['car']} ${themeClass} ${horizontalClass}`}
      data-testid="car"
    >
      <Gallery photo={{ url: photoUrl, label: name }} photoCount={photoCount} theme={theme} horizontal={horizontal} />
      <div className={css.car__details}>
        <div className={css.car__detail_upper}>
          <h2 className={css.car__title}>{name}</h2>
          <PriceDisplay prices={prices} theme={theme} small={horizontal} />
        </div>
        {!horizontal && (
          <div className={css.car__detail_lower}>
            <hr />
            <Parameters parameters={parameters} theme={theme} />
          </div>
        )}
      </div>
    </a>
  );
};

export default Car;
