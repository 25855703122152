import { useEffect, useState } from 'react';
import { Content, ContentProvider } from '.';

const useContentProvider = (contentProvider: ContentProvider) => {
  const [content, setContent] = useState<Content>();
  useEffect(() => {
    (async () => {
      const fetchedContent = await contentProvider();
      setContent({
        ads: fetchedContent.ads,
        searchUrl: fetchedContent.searchUrl,
      });
    })();
  }, [contentProvider]);
  return content;
};

export default useContentProvider;
