import React, { FC } from 'react';
import css from './style.module.scss';
import Header from '../Header';
import SimilarButton, { SimilarButtonProps } from '../SimilarButton';
import CarContainer from '../CarContainer';
import useContentProvider from '../../services/contentProvider/useContentProvider';
import MetricImage from '../MetricImage';
import { BoxProps } from '../../types/boxProps';

const SingleCarBox: FC<BoxProps> = ({ contentProvider, boxStyle = 'normal' }) => {
  const content = useContentProvider(contentProvider);
  const singleBox = `${css['single-box']}${boxStyle === 'narrow' ? ` ${css['single-box--alt']}` : ''}`;

  if (content === undefined) {
    return <div>Loading...</div>;
  }
  const { ads, searchUrl } = content;
  const similarButton: SimilarButtonProps = {
    variant: 'orange',
    link: searchUrl,
    width: 155,
    height: 30,
    margin: 'auto',
    fontSize: 14,
  };
  return (
    <div className={singleBox}>
      <Header trackerUrl="https://ad.adverticum.net/C/7180512/7185681/718568600?ct1=" />
      <CarContainer cars={ads} height={392} padding="16px 16px 10px 16px" />
      <div className={css.buttonContainer}>
        <SimilarButton {...similarButton} />
      </div>
      <MetricImage url="https://ad.adverticum.net/t/?z=7180512&g=7180384&b=718056000&h=[LOCATION]&p=2" />
    </div>
  );
};

export default SingleCarBox;
