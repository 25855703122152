import { ContentProvider, Limit } from '../contentProvider';
import utmParameterProvider from '../utmParameterProvider';

type CtUrls = { adCt: string; searchCt: string };

export const adverticumUrls: { [k: number]: CtUrls } = {
  1: {
    adCt: 'https://ad.adverticum.net/C/7180512/7180398/718058000?ct1=',
    searchCt: 'https://ad.adverticum.net/C/7180512/7180401/718056800?ct1=',
  },
  2: {
    adCt: 'https://ad.adverticum.net/C/7180512/7180396/718058200?ct1=',
    searchCt: 'https://ad.adverticum.net/C/7180512/7180403/718057600?ct1=',
  },
  3: {
    adCt: 'https://ad.adverticum.net/C/7180512/7180397/718057900?ct1=',
    searchCt: 'https://ad.adverticum.net/C/7180512/7180400/718056900?ct1=',
  },
  4: {
    adCt: 'https://ad.adverticum.net/C/7180512/7180399/718058100?ct1=',
    searchCt: 'https://ad.adverticum.net/C/7180512/7180402/718057200?ct1=',
  },
};
const getAdverticumUrl = (limit: Limit, variant: keyof CtUrls) => adverticumUrls[limit][variant];
export const adverticumUrlWrapper = (ctUrl: string, wrapper: string) => {
  const encodedUrl = encodeURIComponent(ctUrl);
  return `${wrapper}${encodedUrl}`;
};
export const addUtmParameters = (ctUrl: string): string => {
  const utmParameters = utmParameterProvider();
  const url = new URL(ctUrl);
  Object.entries(utmParameters).forEach((property) => {
    url.searchParams.append(...property);
  });
  return url.toString();
};

export const adPrefix = (contentProvider: ContentProvider, limit: Limit): ContentProvider => {
  return async () => {
    const content = await contentProvider();
    content.searchUrl = addUtmParameters(content.searchUrl);
    content.searchUrl = adverticumUrlWrapper(content.searchUrl, getAdverticumUrl(limit, 'searchCt'));
    content.ads = content.ads.map((ads) => ({
      ...ads,
      url: adverticumUrlWrapper(addUtmParameters(ads.url), getAdverticumUrl(limit, 'adCt')),
    }));
    return content;
  };
};
