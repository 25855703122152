import { CarProps } from '../../components/Car';
import { adPrefix, adverticumUrls } from '../metricsHandler';

const API_URL = 'https://api.hasznaltauto.hu/v2/car-suggester';
export type Content = {
  ads: CarProps[];
  searchUrl: string;
};

export type Limit = keyof typeof adverticumUrls;

export type SearchParams = {
  brand?: string;
  model?: string;
  fuel_type?: string;
  vintage?: number;
  limit?: Limit;
  year_to?: number;
  price_from?: number;
  price_to?: number;
};

type Site = 'total-car' | 'index';

type RequestSearchParams = { [k in keyof SearchParams]: string };

export type ContentProvider = () => Promise<Content>;

const transformSearchParams = (searchParams: SearchParams): RequestSearchParams =>
  Object.entries(searchParams).reduce((acc, [key, value]) => {
    return value ? { ...acc, [key]: String(value) } : { ...acc };
  }, {}) as RequestSearchParams;

const getRequestSearchParams = (searchParams: SearchParams) => {
  return new URLSearchParams(transformSearchParams(searchParams));
};

const transformResponse = async (response: Response): Promise<Content> => {
  const transformAd = (receivedAd: Omit<CarProps, 'photoUrl'> & { photo: { url: string } }): CarProps => {
    const { photo, ...adWithoutPhoto } = receivedAd;
    return { ...adWithoutPhoto, photoUrl: photo.url };
  };
  const receivedContent = await response.json();
  return { ...receivedContent, ads: receivedContent.ads.map(transformAd) };
};

const fetchData = async (searchParams: SearchParams, site: Site) => {
  const requestSearchParams = getRequestSearchParams(searchParams);
  return transformResponse(await fetch(`${API_URL}/${site}?${requestSearchParams}`));
};

const createContentProvider = (
  searchParamsProvider: (limit: Limit) => SearchParams,
  limit: Limit,
  site: Site
): ContentProvider => adPrefix(async () => fetchData(searchParamsProvider(limit), site), limit);

export default createContentProvider;
