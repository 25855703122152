import React, { FC } from 'react';
import Photo, { PhotoProps } from './components/Photo';
import css from './style.module.scss';
import { ReactComponent as CameraIcon } from './assets/img/camera.svg';
import { Theme } from '../../index';

export type GalleryProps = {
  photo: PhotoProps;
  photoCount: number;
  theme?: Theme;
  horizontal?: boolean;
};

const Gallery: FC<GalleryProps> = ({ photo, photoCount, horizontal, theme }) => {
  const horizontalClass = horizontal ? css['gallery__view-box--horizontal'] : '';

  return (
    <div className={`${css['gallery__view-box']} ${horizontalClass}`}>
      <div className={css['gallery__photo-count']} data-testid="photo-count">
        <CameraIcon className={css['gallery__photo-count-icon']} />
        {photoCount}
      </div>
      <Photo {...photo} key={photo.url} theme={theme} horizontal={horizontal} />
    </div>
  );
};
export default Gallery;
