import React, { FC } from 'react';
import css from './style.module.scss';
import { Theme } from '../../../../index';

export type PhotoProps = {
  url: string;
  label: string;
  horizontal?: boolean;
  theme?: Theme;
};

const Photo: FC<PhotoProps> = ({ url, label, horizontal, theme }) => {
  const themeClass = theme ? css[`photo--${theme}`] : '';
  const horizontalClass = horizontal ? css['photo--horizontal'] : '';

  return (
    <div className={`${css['photo']} ${themeClass} ${horizontalClass}`}>
      <img src={url} alt={label} loading="lazy" />
    </div>
  );
};

export default Photo;
